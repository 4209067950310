.participate {
    padding-top: 10em;
    position: relative;
    overflow-y: hidden;
}

.participation-details {
    font-size: 1.5rem;
    display: flex;
    height: 25em;
    padding: 2rem 15%;
}

.participate .rectangle {
    height: 100%;
    width: 100%;
    background-color: var(--darkgreen);
    top: 0;
    left: 85vw;
}

.participation-details .col-text {
    margin: auto 0;
}

.relative {
    position: relative;
}

.submission-form {
    justify-content: flex-end;
    padding-top: 7em;
}

.submission-form .col-text {
    justify-content: flex-end;
    padding-top: 7em;
    width: 30em;
}

.align-right {
    justify-content: flex-end;
}

.form-row {
    margin: .5em 0;
}

.form-row input {
    margin: .5em 0 1em 0;
}

.form-row input[type=text] {
    width: 100%;
    padding: .5em;
    color: var(--darkgreen);
}

.participate .yellow-triangle-shape {
    z-index: -1;
    right: 60vw;
}

select {
    margin: .5em 0;
    padding: .25em;
}

form p {
    margin: 0;
    font-size: .75em;
    line-height: 1.1em;
}

select#age {
    margin: .5em;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip-text {
    visibility: hidden;
    width: 15em;
    background-color: var(--pink);
    border: 2px solid var(--darkgreen);
    color: var(--darkgreen);
    text-align: center;
    padding: 1em .5em;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
}

.tooltip-container .tooltip-text::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: var(--darkgreen) transparent transparent transparent;
}

textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 10em;
}

label {
    font-weight: bold;
    font-size: .9em;
}

button, input[type="submit"], input[type="reset"] {
    border: none;
    padding: .85rem 1.25rem;
    cursor: pointer;
}

.button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.file-input {
    width: fit-content;
}

.upload-button {
    display: inline;
    font-size: .75em;
}

.modalOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.75);
    z-index: 5;
    display: none;
}

.confirmationModal {
    border: 3px solid var(--pink);
    border-top: 1em solid var(--pink);
    background-color: white;
    padding: 2em 3em;
    width: 30em;
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 10;
}

.openModal {
    display: block;
}

.participate .close-icon {
    position: absolute;
    top: -1em;
    right: -1em;
    color: var(--darkgreen);
}

.participate .col-container:nth-of-type(2) .col-text {
    margin: auto;
}


/***************
   Animations
***************/
.participate .animation .em-shape {
    animation: participateEmShape 10s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -15s);
    animation-iteration-count: 1;
}

@keyframes participateEmShape {
    from {
        left: 60vw;
        top: 0;
    }

    to {
        left: 40vw;
        top: 20vh;

    }
}

.participate .animation .rectangle {
    animation: participateGreenRect 10s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -15s);
    animation-iteration-count: 1;
}

@keyframes participateGreenRect {
    from {
        left: 95vw;
        top: 0;

    }

    to {
        left: 70vw;
        top: 20vh;

    }
}

.participate .animation.yellow-triangle-shape {
    animation: participateYellowTri 5s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -7s);
    animation-iteration-count: 1;
}

@keyframes participateYellowTri {
    from {
        right: 80vw;
        top: 5vh;
    }

    to {
        right: 50vw;
        top: -10vh;
    }
}

/*Extra Large Sized Screens*/
@media screen and (min-width: 1700px) {
    .participate .rectangle {
        left: 68vw;
    }
    @keyframes participateGreenRect {
    from {
        left: 80vw;
        top: 0;

    }

    to {
        left: 60vw;
        top: 20vh;

    }}
}

/*Tablet Sized Screen*/
@media screen and (max-width: 900px) {
    .participation-details .col-text{
        font-size: 1.2rem;
    }

    .participate .rectangle {
        visibility: hidden;
    }

    .participate .description {
        font-size: 1.2rem;
    }
}

/*Tablet screen sizes*/
@media screen and (max-width: 768px) {
    .participate {
        padding-top: 10em;
    }

    .participate .col-container {
        justify-content: center;
    }

    .participate .col-text {
        width: 65%;
    }

    .participate .col-container:nth-of-type(2) .col-text {  width: 90%;
    }

    .submission-form {
        padding-top: 12em;
    }

    .submission-form .col-text {
        width: 100%;
    }

    .confirmationModal {
        left: 50%;
        top: 54%;
        transform: translate(-50%, -50%);
        width: 90%;
    }

    .confirmationModal p {
        font-size: .8em;
        line-height: 1.4em;
    }

    .confirmationModal h3 {
        font-size: .9em;
    }

    .confirmationModal h4 {
        font-size: 1em;
    }

    .new-submission-link {
        text-align: center;
    }
}

/*Mobile screen sizes*/
@media screen and (max-width: 480px) {
    .participate {
        padding-top: 7.5em;
    }

    .participate .content-section {
        min-height: fit-content;
    }

    form {
        font-size: .85em;
    }

    .participate .em-shape, .participate .rectangle-shape {
        display: none;
    }

    .participation-details .col-text {
        width: 100%;
    }

    .submission-form {
        width: 100%;
    }

    .confirmationModal {
        padding: 3em 2em;
    }
}
