/* Apply a natural box layout model to all elements, but allowing components to change
Credit: Paul Irish
 */
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

/* Global Styles */
:root {
    --red: #eb4827;
    --yellow: #ddd413;
    --blue: #0387c8;
    --pink: #f5dbd9;
    --darkgreen: #264d3b;
    --brown: #8d683f;
    --black: #000;
    --white: #fff;
}

body {
    font-family: forma-djr-deck, sans-serif;
    letter-spacing: .075rem;
    font-size: 1.4rem;
    position: relative;
    width: 100vw;
    overflow-x: hidden;
}

img {
    width: 100%;
}

.background{
    width: 150%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
}


a {
    text-decoration: none;
    color: var(--black);
    border-bottom: 2px solid var(--yellow);
    font-weight: 500;
}

.button {
    background-color: var(--red);
    padding: .85rem 1.25rem;
    font-family: FatFrank, sans-serif;
    font-size: 1.25rem;
    color: var(--white);
    border: solid 2px white;
}

.button:hover {
    background-color: var(--pink);
}

h1, h2, h3, h4, h5 {
    line-height: 1.1em;
}

h1 {
    margin: 0;
}

h2 {
    font-family: fatfrank, sans-serif;
    color: var(--darkgreen);
}

h3 {
    font-family: fatfrank, sans-serif;
    font-size: .95em;
    color: var(--yellow);
    margin: .25em 0;
}

h4 {
    font-family: fatfrank, sans-serif;
    font-size: 1.25em;
    color: var(--darkgreen);
    margin: .25em 0 1em 0;
}

h5 {
    font-size: .95em;
    line-height: 1.3;
    color: var(--blue);
    margin: .5em 0 .5em 0;
    font-weight: normal;
}

h6 {
    font-size: 1.25em;
    margin: .5em 0;
    font-family: FatFrank, sans-serif;
    color: var(--red);
}

p {
    margin: 1em 0 2em 0;
    line-height: 2.25rem;
}

em {
    color: var(--blue);
    font-style: normal;
    line-height: 1.5;
}

.italic {
    font-style: italic;
}

.center-container {
    display: flex;
    flex-direction: column;
}
.center {
    margin: auto;
}

.col-container {
    display: flex;
}

.col-text {
    width: 20em;
}

.col-half {
    flex-basis: 50%;
}

.col-twothirds {
    flex-basis: 66%;
}

.col-onethird {
    flex-basis: 33%;
}

.content-section {
    padding: 5rem 15%;
    position: relative;
    width: 100vw;
    min-height: 25em;
}

main {
    width: 100vw;
    overflow-x: hidden;
}

.submission-gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 3em 5vw;
}

.submission-gallery .circle {
    margin: 1em;
}

/* Shapes Section */
.shape {
    position: absolute;
}

.blue-circle-shape {
    background-color: var(--blue);
    width: 13em;
    height: 13em;
    border-radius: 100%;
}

.yellow-triangle-shape {
    width: 0;
    height: 0;
    border-top: 100em solid var(--yellow);
    border-right: 100em solid transparent;
}

/*Tablet Sized Screens*/
@media screen and (max-width: 900px) {

}

@media screen and (max-width: 768px) {
    .col-container {
        display: flex;
        flex-direction: column;
    }

    .col-text {
        width: 50vw;
    }

    .col-half {
        flex-basis: 70vw;
    }

    .content-section {
        padding: 5rem 15%;
        position: relative;
        width: 100vw;
    }
}

@media screen and (max-width: 480px) {
}