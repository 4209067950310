.about {
    padding-top: 10em;
    overflow: hidden;
}

.about .em-shape {
    left: 60vw;
    top: 0;
    width: 40vw
}

.about .rectangle {
    height: 100%;
    width: 100%;
    background-color: var(--darkgreen);
    top: 0;
    left: 85vw;
}

.about .content-section {
    align-items: center;
}

.red-rectangle {
    position: absolute;
    height: 2.5em;
    width: 60vw;
    background-color: var(--red);
    top: 0;
    right: 40vw;
}

.brown-rectangle {
    position: absolute;
    height: 5em;
    width: 50vw;
    background-color: var(--brown);
    bottom: 0;
    left: 50vw;
}

.about .col-container:nth-of-type(2) .col-text {
    margin: auto;
}

.about .col-container:nth-of-type(3) .col-text {
    margin: auto 0 auto auto;
}

/***************
   Animations
***************/
.animation.red-rectangle {
    animation: slideFromLeftRed 5s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -2s);
    animation-iteration-count: 1;
}

@keyframes slideFromLeftRed {
    from {
        right: 60vw;
        top: 0;

    }

    to {
        right: 50vw;
        top: -30vh;
    }
}

.about .animation .em-shape {
    animation: slideFromRightEmShape 5s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -10s);
    animation-iteration-count: 1;
}

@keyframes slideFromRightEmShape {
    from {
        left: 60vw;
        top: 0;
    }

    to {
        left: 50vw;
        top: 10vh;

    }
}

.about .animation .rectangle {
    animation: slideFromRightGreenRect 5s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -10s);
    animation-iteration-count: 1;
}

@keyframes slideFromRightGreenRect {
    from {
        left: 92vw;
        top: 0;

    }

    to {
        left: 70vw;
        top: 10vh;

    }
}

.animation.brown-rectangle {
    animation: slideFromRightBrownRect 5s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -8s);
    animation-iteration-count: 1;
}

@keyframes slideFromRightBrownRect {
    from {
        left: 60vw;
        bottom: -10vh;
    }

    to {
        left: 50vw;
        bottom: 0;
    }
}

.animation.yellow-triangle-shape {
    animation: slideFromLeftYellow 7s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -7s);
    animation-iteration-count: 1;
}

@keyframes slideFromLeftYellow {
    from {
        right: 80vw;
        top: -10vh;
    }

    to {
        right: 50vw;
        top: 5vh;
    }
}

/*Extra Large Sized Screens*/
@media screen and (min-width: 1700px) {
    .about .rectangle {
        left: 76vw;
    }
    .about .call-to-participate {
        margin-top: 25vw;
    }

    @keyframes slideFromRightGreenRect {
        from {
            left: 80vw;
            top: 0;
        }

        to {
            left: 60vw;
            top: 10vh;
        }
    }
}

/*Tablet Screen Size*/
@media screen and (min-width: 900px) and (max-width: 1700px) {
    .about .rectangle {
        left: 90vw;
    }
}

/*Tablet Screen Size*/
@media screen and (max-width: 900px) {
    .about .rectangle {
        visibility: hidden;
    }
}

/*Small Screen Size*/
@media screen and (max-width: 768px) {
    .about .col-container {
        justify-content: center;
    }

}

/*Mobile Screen Size*/
@media screen and (max-width: 480px) {
    .about .col-text p {
        font-size: .75em;
        line-height: 1.5em;
    }

    .about .col-text {
        width: 70vw;
    }
}
