.exhibits-overview {
    padding-top: 7em;
}

.exhibits-overview .exhibit-preview {
}

.exhibits-overview .exhibit-preview:nth-of-type(2) .yellow-triangle-shape {
    border-top: 40em solid var(--blue);
}

.exhibits-overview .exhibit-preview:nth-of-type(2) h3 {
    color: var(--blue);
}

.exhibits-overview .exhibit-preview:nth-of-type(2) .button {
    background-color: var(--blue);
    color: white;
}

.exhibits-overview .exhibit-preview:nth-of-type(2) .button:hover {
    background-color: var(--red);
    color: white;
}