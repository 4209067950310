.yellow-triangle-shape {
    top: 0;
    right: 50vw;
}

.exhibit-preview {
    text-align: right;
    min-height: 30em;
}

h4 {
    margin-bottom: 1.5em;
}

.hang-off-screen {
    position: absolute;
    right: -6em;
    display: inline-flex;
}

.hang-off-screen .circle {
    margin: 1em;
}

.exhibit-link {
    background-color: var(--yellow);
    color: var(--darkgreen);
    position: absolute;
    bottom: 17em;
    right: 4.5em;
}

.exhibit-preview .circle:hover .overlay {
    visibility: hidden;
}

/* Animations */
.exhibit-preview .animation.yellow-triangle-shape {
    animation: animationHomeYellow 10s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -10s);
    animation-iteration-count: 1;
}

@keyframes animationHomeYellow {
    from {
        right: 40vw;
    }

    to {
        right: 90vw;
    }
}


/*Tablet Sized Screens*/
@media screen and (max-width: 900px) {
    .exhibit-preview {
        display: flex;
        flex-direction: column;
    }

    .hang-off-screen {
        top: 10.5em;
    }

    .exhibit-preview h4 {
        width: 50vw;
        align-self: flex-end;
    }
}

@media screen and (max-width: 768px) {
    .exhibit-preview {
        display: flex;
        flex-direction: column;
        padding-bottom: 5rem;
    }

    .exhibit-preview h4 {
        align-self: flex-end;
    }

    .hang-off-screen {
        position: relative;
        display: flex;
        flex-direction: column;
        right: unset;
        align-items: center;
        top: unset;
    }

    .exhibit-link {
        background-color: var(--yellow);
        color: var(--darkgreen);
        position: relative;
        bottom: unset;
        right: unset;
        margin: auto;
        width: fit-content;
    }
}