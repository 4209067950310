/* Footer Component Styles */
footer {
    background-color: var(--darkgreen);
    padding: 4rem 4em 2rem 4em;
    width: 100%;
    color: white;
    display: flex;
    position: relative;
}

footer a {
    color: white;
    position: relative;
}

.footer-nav li:not(:last-child) {
    margin: .5em 0;
}


.social {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5em;
    margin: 0;
}

.social li {
    padding-left: 1em;
}
.right-side {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.right-side p {
    font-size: .75em;
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    footer {
        padding: 1em 2em;
    }
}