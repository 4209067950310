.circle {
    position: relative;
    height: 12em;
    width: 12em;
    border-radius: 100%;
    overflow: hidden;
}

.circle a {
    height: 100%;
    width: unset;
    display: inline;
    border-bottom: none;
}

.circle-img {
    min-height: 100%;
    min-width: 100%;
    max-height: 170%;
    max-width: 170%;
    width: auto;
    height: auto;
}

.circle p {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    margin: 0;
    width: 95%;
    color: var(--darkgreen);
    text-align: center;
}

.circle:hover p {
    visibility: visible;

}

.circle:hover .overlay {
    background-color: rgba(255,255,255,.75);
    position: absolute;
    width: 12em;
    height: 12em;
    border-radius: 100%;

}



