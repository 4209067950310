.full-collection {
    padding-top: 15em ;
    position: relative;
    min-height: 42em;
    overflow-y: hidden;
}

.full-collection .content-section {
    min-height: 0;
}

.full-collection .yellow-triangle-shape {
    top: 7em;
    right: 70vw;
    border-top: 100em solid var(--darkgreen);
}

.full-collection h3, .full-collection h4 {
    padding: 0;
}

.full-collection h3 {
    color: var(--brown);
    margin-top: 2em;
}

/*Extra Large Sized Screens*/

@media screen and (min-width: 1700px) {
    .full-collection .yellow-triangle-shape {
        right: 75vw;
    }
}

/*Tablet Sized Screens*/
@media screen and (max-width: 900px) {
    .full-collection .yellow-triangle-shape {
        right: 60vw;
    }

    .full-collection {
        padding-top: 45vw;
    }
}

/*Small Sized Screens*/
@media screen and (max-width: 768px) {
     .full-collection .yellow-triangle-shape {
         right: 50vw;
     }
}