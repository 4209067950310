.current-exhibit {
    padding-top: 15em ;
    position: relative;
}

.current-exhibit .yellow-triangle-shape {
    transform: scale(.5);
    top: -2em;
    left: -15em;
}

.current-exhibit .content-section {
    min-height: 0;
}

.current-exhibit h3, .current-exhibit h4 {
    padding: 0;
}

.current-exhibit h3 {
    color: var(--brown);
    margin-top: 2em;
}

.current-exhibit .content-section:nth-of-type(1) {
    padding: 5em 5vw;
}

.gallery .circle {
    margin: 1em;
}

.filtering h5 {
    color: white;
}
