.hero {
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
}

.hero h2 {
    margin: auto;
    font-size: 4vw;
}

.hero .outlined {
    -webkit-text-stroke: 2px var(--darkgreen);
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.hero .hidden {
    visibility: hidden;
}
.hero .rotating {
    position: absolute;
    transform: translatex(-25vw);
    color: var(--yellow);
}

.fa-angle-down {
    color: var(--blue);
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translatex(-50%);
    font-size: 2em;
}

.home .description {
    font-size: 1.5rem;
    display: flex;
    height: 25em;
}

.home .rectangle {
    height: 100%;
    width: 140vw;
    background-color: var(--darkgreen);
    top: 0;
    left: 95vw;
}

.home .em-shape {
    left: 65vw;
}

.description .col-text {
    margin: auto 0;
}

.call-to-action:nth-child(4), .call-to-action:nth-of-type(5) {
    padding-top: 0;
}

.call-to-action {
    align-items: center;
}

.circle-text {
    transform:translate(2em, 8em);
    background-color: var(--blue);
    padding: 9em 4.75em;
    height: 20em;
    width: 20em;
    border-radius: 100%;
    color: white;
    font-size: 1.85vw;
    line-height: 2.2vw;
    margin: 0;
    position: absolute;
    right: 20vw;
    bottom: 5vh;
}

.call-to-action .hero {
    height: fit-content;
    padding: 0 15%;
}

.cta-section-two {
    padding: 15em 15% 5rem 0;
}

.cta-section-two .multiple-circle {
    width: 100%;
    padding: 0 3em 0 0;
    z-index: -1;
}

.cta-section-two .col-image {
    flex-basis: 50%;
}

.call-to-action .col-two {
    align-self: flex-end;
}

.call-to-action .rectangle-shape {
    height: 1.5em;
}

.home .rectangle-shape {
    top: 0;
}

.call-to-action:nth-child(6) .button {
    margin: 2em 0;
}

/* Animations */
.home .animation .em-shape {
    animation: slideAcrossEmShape 8s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -30s);
    animation-iteration-count: 1;
}

@keyframes slideAcrossEmShape {
    from {
        left: -40vw;
    }

    to {
        left: 65vw;
    }

}

.home .animation .rectangle {
    animation: homeSlideRect 8s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -30s);
    animation-iteration-count: 1;
}

@keyframes homeSlideRect {
    from {
        left: 0;
    }

    to {
        left: 98vw;
    }

}

.home .animation.circle-text {
    animation: blueCircleText 8s ease-in-out infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -5s);
    animation-iteration-count: 1;
}

@keyframes blueCircleText {
    0% {
        right: 30vw;
        bottom: 10vh;
    }

    25% {
        right: 25vw;
        bottom: -2.5vh;
    }

    50% {
        right: 20vw;
        bottom: -10vh;
    }

    75% {
        right: 15vw;
        bottom: -2.5vh;
    }

    100% {
        right: 10vw;
        bottom: 10vh;
    }
}


/*Extra Large Sized Screens*/
@media screen and (min-width: 1500px) {
    .home .rectangle {
        left: 90vw;
    }

    .home .em-shape {
        left: 70vw;
    }
    @keyframes slideAcrossEmShape {
        from {
            left: -40vw;
        }

        to {
            left: 70vw;
        }
    }

    @keyframes homeSlideRect {
        from {
            left: -15vw;
        }

        to {
            left: 95vw;
        }
    }

    .home .circle-text {
        margin-bottom: 4em;
        font-size: 1.5vw;
    }

    .home .cta-section-two {
        align-items: center;
        justify-content: center;
    }
}


/*Tablet Sized Screens*/
@media screen and (max-width: 900px) {
    .home .rectangle {
        left: 95vw;
    }

    .home .description {
        font-size: 1.2rem;
    }

    .hero .rotating {
        text-align: center;
        transform: translateX(-6.35em);
    }

    .call-to-action {
        padding-top: 10em;
    }

    .call-to-action .hero {
        flex-direction: column;
    }

    .circle-text {
        background-color: var(--blue);
        padding: 7em 4.75em;
        height: 42vw;
        flex-basis: 80%;
        border-radius: 100%;
        color: white;
        font-size: 2.5vw;
        line-height: 3.0vw;
    }

    .call-to-action .participation-details {
        padding-top: 8em;
        font-size: .9em;
    }

    .call-to-action .col-container.participation-details .col-text {
        width: 50vw;
    }

    .call-to-action .col-container.participation-details {
        align-items: flex-end;
    }
}

@media screen and (max-width: 768px) {
    .hero {
        width: 100vw;
        height: 100vh;
        display: flex;
        position: relative;
    }

    .hero h2 {
        margin: auto;
        font-size: 6vw;
        text-align: center;
        max-width: 65vw;
    }

    .hero .outlined {
        -webkit-text-stroke: .035em var(--darkgreen);
        -webkit-text-fill-color: rgba(255, 255, 255, 0);
    }

    .hero .rotating {
        text-align: center;
        transform: translateX(0);
        position: relative;
    }

    .hero .hidden {
        display: none;
    }

    .home .rectangle {
        left: 100vw;
    }

    .call-to-action:nth-child(4) {
        padding-top: 5em;
    }

    .call-to-action .col-container.participation-details {
        justify-content: flex-end;
    }

    .call-to-action .col-text {
        width: 70vw;
    }

    .call-to-action h4 {
        width: 70vw;
    }

    .circle-text {
        transform:translate(0, 0);
        background-color: var(--blue);
        padding: 8.5em 3.9em;
        height: 65vw;
        width: 65vw;
        border-radius: 100%;
        color: white;
        font-size: 3.5vw;
        line-height: 4.5vw;
    }

    @keyframes blueCircleText {
        0% {
            right: 40vw;
            bottom: -20vh;
        }

        25% {
            right: 30vw;
            bottom: -22.5.5vh;
        }

        50% {
            right: 20vw;
            bottom: -30vh;
        }

        75% {
            right: 10vw;
            bottom: -22.5vh;
        }

        100% {
            right: 0;
            bottom: -20vh;
        }
    }

    .multiple-circle {
        padding: 0;
        width: 100%;
    }

    .cta-section-two .col-two {
        margin: 0 15%;
        width: 80%;
    }

    .call-to-action {
        padding-top: 2em;
    }
}

@media screen and (max-width: 480px) {
    .home .rectangle {
        visibility: hidden;
    }

    .hero h2 {
        margin: auto;
        font-size: 7.25vw;
        max-width: 75vw;
    }

    .hero h2 .rotating {
        text-align: center;
        width: 100%;
    }

    .call-to-action .content-section:nth-of-type(5) {
        padding: 1em 15%;
    }

    .call-to-action .col-container.participation-details .col-text {
        width: 70vw;
    }

    .call-to-action .col-text p {
        font-size: .75em;
        line-height: 1.5em;
    }

    .call-to-action .col-text {
        width: 70vw;
    }

    .circle-text {
        transform: translate(0, 0);
        background-color: var(--blue);
        padding: 9em 4.75em;
        height: 80vw;
        width: 80vw;
        border-radius: 100%;
        color: white;
        font-size: 4vw;
        line-height: 5vw;
    }

    .animation, .animation .em-shape, .animation .rectangle {
        animation: none !important;
    }

    .circle-text {
        bottom: -5em;
        left: 50%;
        transform: translateX(-50%);
    }

    .cta-section-two {
        padding: 6em 15% 2em 15%;
        min-height: unset;
    }

    .cta-section-two .multiple-circle {
        padding: 0;
    }

    .cta-section-two .col-two {
        margin: 0;
        width: 100%;
    }

    .cta-section-two .col-two p {
        font-size: .75em;
        line-height: 1.5rem;
    }

    .call-to-action {
        padding-top: 0;
    }

    .call-to-action .center  {
        padding: 0 0 1em 0;
        margin: unset;
    }

}