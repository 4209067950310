/* Header Styles */
header {
    width: 100vw;
    padding: 1.5em 1em 0 1em;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 1;
    background: white;
}

.logo {
    width: 10em;
    border-bottom: none;
}


header a {
    color: var(--darkgreen);
}

/* Navigation Styles*/
.main-nav {
    display: flex;
}

.main-nav ul {
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.main-nav .nav-item {
    position: relative;
    margin:.5em;
    padding: 0 0 .1em 0;
    font-size: .8em;
}

.main-nav ul ul {
    align-items: flex-start;
    font-size: .9em;
}

.nav-item .sub-nav {
    flex-direction: column;
    display: none;
    position: absolute;
    top: 1.25em;
    left: -.5em;
}

.sub-nav li {
    padding: .5em .25em .5em 0;
    position: relative;
}

.navigation a {
    border-bottom: none;
    width: fit-content;
    padding: 0;
}

.navigation ul {
    list-style-type: none;
    padding: 0;
}

.navigation li a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 5px;
    bottom: -.25em;
    left: 0;
    background-color: var(--red);
}

.navigation li a:hover::after {
    visibility: visible;
    width: 100%;
    transition: all 0.4s ease-in-out 0s;
}

.navigation a.main-nav-active-link::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -.25em;
    left: 0;
    background-color: var(--red);
    z-index: 5;
}

.nav-item:hover .sub-nav {
    display: block;
    position: absolute;
    margin: 0;
    text-align: center;
    z-index: 10;
    background-color: var(--yellow);
    padding: 1em 2em;
    transform: translateX(-25%);
}

.sub-nav:hover {
    display: block;
}

.sub-nav .sub-nav-item::after {
    background-color: var(--white);
    height: 5px;
    bottom: .15em;
}

.sub-nav .sub-nav-item:hover::after {
    visibility: visible;
    width: 100%;
    transition: all 0.4s ease-in-out 0s;
}

.logo h1, .logo h2 {
    width: fit-content;
}

header .rectangle-shape {
    position: absolute;
    height: 1em;
    width: 100vw;
    background-color: var(--pink);
    top: 0;
    left: 0;
}

header .main-nav .menu-icon, header .main-nav .close-icon {
    display: none;
}


/*Tablet Sized Screens*/
@media screen and (max-width: 768px) {
    header {
        flex-direction: column;
    }

    header .main-nav .menu-icon {
        display: block;
        position: absolute;
        top: 2.5em;
        right: 1em;
        font-size: 1.25em;
        color: var(--brown);
    }

    header .main-nav ul {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    header .main-nav .nav-item .sub-nav {
        height: fit-content;
        position: relative;
    }

    header .main-nav ul li {
        position: relative;
        margin: .5em 1em;
    }

    header .logo {
        margin: auto;
        width: 100%;
    }

    header h1 {
        max-width: 9em;
    }

    header h2 {
        font-size: .9em;
    }

    header .main-nav.nav-open {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: var(--darkgreen);
        padding: 3em 2em;
        color: white;
    }

    header a {
        color: white;
    }

    header .main-nav.nav-open ul {
        display: flex;
        top: unset;
        margin: 0;
    }

    header .main-nav.nav-open ul ul {
        display: block;
        padding: 1em 2em;
    }


    header .main-nav.nav-open .menu-icon {
        display: none;
    }

    header .main-nav.nav-open .close-icon {
        display: block;
        position: absolute;
        top: 1.5em;
        right: 1.5em;
    }

    .nav-item:hover .sub-nav {
        display: block;
        text-align: left;
        background-color: transparent;
        transform: translateX(0);
    }

    a.main-nav-active-link {
        width: fit-content;
    }

    .navigation a{
        position: relative;
    }

    .navigation li a::after, .navigation li a:hover::after, .navigation a.main-nav-active-link::after {
        bottom: unset;
        top: 1.25em;
    }
}

@media screen and (max-width: 480px) {
    header {
        padding: 1.5em .5em .5em .5em;
    }

    h1 {
        width: 85%;
    }

    header .main-nav .menu-icon{
        top: 2em;
        right: .75em;
    }
}