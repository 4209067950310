.submission {
    padding-top: 13em;
}

.submission-section {
    min-height: 40em;
}

.submission .col-container {
    padding-bottom: 1em;
}

.submission .content-section {
    padding-top: 0;
}

.submission .breadcrumbs {
    font-size: .75em;
    padding: 1em 17%;
}

.submission .carousel-container {
    position: relative;
}

.submission .submission-text {
    font-size: 1.15em;
    color: var(--darkgreen);
    padding: 1.25em;
    white-space: pre-line;
    margin: 1em 0.25em;
}

.submission .submission-text p {
    margin: 0;
    padding: 1em;
}

.submission-link {
    padding: 0 0 1em 0;
    color: var(--blue);
}

.submission-link a {
}

.submission h5 {
    color: black;
}

.submission-info-section {
    margin: .75em;
    padding: 0 1em;
}

.submission-info-section strong {
    color: var(--brown)
}

.submission-description-container {
    margin: 1.75em;
    min-height: unset;
}

.submission-description-container p {
    margin: 0 0 2em 0;
}

.submission .call-to-participate {
    margin-top: 7rem;
    padding-top: 10rem;
}

/* Carousel Slider
.awssld.awssld {
    --organic-arrow-height: 20px;
    --slider-height-percentage: 100%;
    --slider-transition-duration: 538ms;
    --organic-arrow-thickness: 5px;
    --organic-arrow-border-radius: 5px;
    --organic-arrow-color: var(--yellow);
    --control-button-width: 12%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: var(--yellow);
    --control-bullet-active-color: var(--yellow);
    --loader-bar-color: #ddd413;
    --loader-bar-height: 5px;
    --content-background-color: #fff;
    z-index: 0;
}

.awssld__controls button .awssld__controls__arrow-left, .awssld__controls button .awssld__controls__arrow-right {
    opacity: 100% !important;
}

.awssld__bullets {
    bottom: 0 !important;
}

.awssld__content, .awssld__box, .awssld--active, .awssld__container {
    height: max-content !important;
}*/

/* Carousel */
.col .carousel .slide {
    background: white;
    text-align: left;
}

.col .thumbs-wrapper {
    display: none;
}

.col .carousel .control-dots .dot {
    background: var(--yellow);
    width: .5em;
    height: .5em;
}

.col .carousel .carousel-status {
    display: none;
}

.col .carousel .control-arrow, .col .carousel.carousel-slider .control-arrow {
    opacity: 1;
}

.col .carousel .control-arrow:hover, .col .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}

.col .carousel .control-prev.control-arrow:before {
    border-right: .75em solid var(--yellow);
}

.col .carousel .control-next.control-arrow:before {
    border-left: .75em solid var(--yellow);
}

.col .carousel .control-arrow:before, .col .carousel.carousel-slider .control-arrow:before {
     margin: 0 5px;
     display: inline-block;
     border-top: .75em solid transparent;
     border-bottom: .75em solid transparent;
     content: '';
}

.col .carousel .slide img {
    padding: .05em;
}



@media screen and (max-width: 1200px) {
    .submission .col-container {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 950px) {
    .submission .col-container {
        display: flex;
        flex-direction: column;
    }

    .submission .col-half {
        flex-basis: unset;
        height: fit-content;
    }

    .submission .content-section:nth-child(1) {
        padding: 2rem 15%;
        position: relative;
        width: 100vw;
        min-height: 20em;
    }

    .submission .breadcrumbs {
        padding: 0 15%;
    }

    .submission .col-text {
        width: 100%;
        padding: 1em 0;
        margin: 0;
    }

    .submission-description-container {
        margin: 0;
        padding: 0  15%;
        width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .submission {
        padding-top: 3em;
    }

    .submission .breadcrumbs {
        padding: 0 10%;
    }

    .submission .col-text {
        width: 100%;
        padding: 1em 0;
        margin: 0;
    }

    .submission-description-container {
        margin: 0;
        padding: 5em 15%;
        width: 100%;
    }
}